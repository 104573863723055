import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Grid from "./Grid";
import "./App.css";

function App() {
  const [selectedTile, setSelectedTile] = useState(null);
  const [grid, setGrid] = useState(
    Array(20)
      .fill()
      .map(() => Array(20).fill(null))
  );
  const [showLabels, setShowLabels] = useState(false);

  const handleTileClick = (row, col) => {
    if (selectedTile !== null) {
      const newGrid = [...grid];
      newGrid[row][col] = selectedTile;
      setGrid(newGrid);
    }
  };

  return (
    <div className="app">
      <Sidebar setSelectedTile={setSelectedTile} showLabels={showLabels} setShowLabels={setShowLabels} />
      <Grid grid={grid} handleTileClick={handleTileClick} showLabels={showLabels} />
    </div>
  );
}

export default App;
