import React from "react";

function Grid({ grid, handleTileClick, showLabels }) {
  return (
    <div className="grid">
      {grid.map((row, rowIndex) =>
        row.map((tile, colIndex) => (
          <div
            key={`${rowIndex}-${colIndex}`}
            className={`tile ${tile || "empty"}`}
            onClick={() => handleTileClick(rowIndex, colIndex)}
          >
            {showLabels && tile && <span className="tile-label">{tile}</span>}
          </div>
        ))
      )}
    </div>
  );
}

export default Grid;
