import React from "react";

const tileTypes = [
  "empty",
  "grass",
  "top-left-water",
  "top-middle-water",
  "top-right-water",
  "middle-left-water",
  "middle-right-water",
  "bottom-left-water",
  "bottom-middle-water",
  "bottom-right-water",
];

function Sidebar({ setSelectedTile, showLabels, setShowLabels }) {
  return (
    <div className="sidebar">
      <div className="label-toggle">
        <label>
          <input type="checkbox" checked={showLabels} onChange={(e) => setShowLabels(e.target.checked)} />
          Labels
        </label>
      </div>
      {tileTypes.map((type, index) => (
        <button key={index} className={`tile-button ${type}`} onClick={() => setSelectedTile(type)}>
          {type}
        </button>
      ))}
    </div>
  );
}

export default Sidebar;
